// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./uc5yZOCV4-0.js";

const cycleOrder = ["CF5bYIaKI"];

const serializationHash = "framer-zEXdE"

const variantClassNames = {CF5bYIaKI: "framer-v-1ybkmgw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CF5bYIaKI", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ybkmgw", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"CF5bYIaKI"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1v5ikjc"} data-framer-name={"home"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"EnV40JcXe"} svg={"<svg width=\"24\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g fill=\"none\"><path d=\"M4 8.9c0-1.715 0-2.573.334-3.228a3.063 3.063 0 0 1 1.338-1.338C6.327 4 7.185 4 8.9 4h6.2c1.715 0 2.573 0 3.228.334a3.063 3.063 0 0 1 1.338 1.338C20 6.327 20 7.185 20 8.9v6.2c0 1.715 0 2.573-.334 3.228a3.063 3.063 0 0 1-1.338 1.338C17.673 20 16.815 20 15.1 20H8.9c-1.715 0-2.573 0-3.228-.334a3.063 3.063 0 0 1-1.338-1.338C4 17.673 4 16.815 4 15.1V8.9Z\" fill=\"#F6F6F6\"/><path d=\"M12.806 14.346a.47.47 0 0 1 .11-.302l.955-1.133a.469.469 0 0 1 .359-.167h1.975c.138 0 .27.061.358.167l.955 1.133c.072.084.11.191.11.302v2.72c0 .26-.209.47-.468.47h-3.885a.469.469 0 0 1-.469-.47v-2.72ZM6.45 13.278c0-.445.492-.714.866-.473l1.194.766a.563.563 0 0 0 .608 0l1.194-.766a.562.562 0 0 1 .866.473v3.72c0 .31-.252.562-.563.562H7.012a.563.563 0 0 1-.562-.562v-3.72Zm0-5.208a.56.56 0 0 1 .228-.453l1.793-1.324a.562.562 0 0 1 .668 0l1.793 1.324a.562.562 0 0 1 .228.452v2.53c0 .31-.252.562-.562.562H7.012a.563.563 0 0 1-.562-.562v-2.53Zm6.387.59a2.396 2.396 0 0 1 4.792 0v2.13a.367.367 0 0 1-.367.367h-4.057a.368.368 0 0 1-.368-.368V8.66Z\" fill=\"#1C1C1C\"/></g></svg>"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-zEXdE[data-border=\"true\"]::after, .framer-zEXdE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zEXdE.framer-cgpjer, .framer-zEXdE .framer-cgpjer { display: block; }", ".framer-zEXdE.framer-1ybkmgw { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-zEXdE .framer-1v5ikjc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Frameruc5yZOCV4: React.ComponentType<Props> = withCSS(Component, css, "framer-zEXdE") as typeof Component;
export default Frameruc5yZOCV4;

Frameruc5yZOCV4.displayName = "Nav/Icon -- Home";

Frameruc5yZOCV4.defaultProps = {height: 24, width: 24};

addFonts(Frameruc5yZOCV4, [])